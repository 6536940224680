import React from 'react';
import './App.css';
import Barra from './barra';

function Bio() {
  return (
    <div>
      <Barra/>
      <div>
      <section className=" ">
      <div className="info mt-3">
        <h1>Conociendo a Johnatan Mena</h1>
          </div>
      </section>
      <div className="mena container-lg container-md mt-lg-5 py-lg-4 mt-md-1 py-md-4 mt-sm-1 py-sm-3  mt-2 py-3 ">
            <p className="bio-text_mena">
              Johnatan Alexander Mena Salcedo nació
              el 12 de noviembre de 1991 en la Ciudad de Medellín, creció en el
              municipio de Chigorodó Antioquia y actualmente vive en su ciudad natal, 
              a la edad de 12 años mostró interés por los asuntos tecnológicos experimentando
              con computadoras de la época, fue su interés por la música lo que lo lleva a conocer
              más el mundo de la informática, desarollando una gran habilidad con software para edición, grabación
              y producción de audio, a la edad de 15 años empieza a generar dinero
              formateando computadoras, grabando canciones caseras, realizando diseños fotográficos y quemando cd's musicales. 
            </p>
            <div className="info1">
            <img
              src="../old.jpg"
              alt="Foto de Mena"
              width={197}
            />
            <img
              src="../old2.jpg"
              alt="Foto de Mena"
              width={350}
            />
          </div>
          <p></p>
            <p></p>
              <p className="bio-text_mena">
              Luego de trabajar en diferentes instituciones de salud en la región de Urabá
              se traslada a la ciudad de Medellín en el año 2015 para estudiar ingeniería biomédica,
              es allí dónde retoma su pasión por la computación e inicia a dar sus primeros pasos en
              la programación y sistemas electrónicos. En su paso por la universidad conoce a sus socios
              Mateo Osorio y Daniel Arteaga y logra desarrollar 
              proyectos de machine learning con Python y Matlab e inicia su carrera como desarrollador 
              web creando un juego en javascript al cual llama Turkano's Game.
            </p>
            <div className="info1">
            <img
              src="../itm3.jpg"
              alt="Foto de Mena"
              width={250}
            />
             <img
              src="../imt2.jpg"
              alt="Foto de Mena"
              width={330}
            />
          </div>
          <p></p>
            <p></p>
              <p className="bio-text_mena">
              En el año 2021 Johnatan Mena consigue el título de ingeniero biomédico y su vez inicia su carrera
              profesional en la empresa Análisis Metrológico Industrial con el cargo de metrólogo, en dicha empresa 
              a pesar de tener un cargo más operativo desarolla de forma independiente una aplicación web para el 
              control de equipos patrón y con ese desarrollo se abrió camino para conseguir otras 
              oportunidades a nivel laboral, con su experiencia como metrólogo en el sector de la salud logra identificar
              algunas necesidades relacionadas con la gestión de los equipos biomédicos y es allí dónde se le ocurre la idea 
              de importar beacons ble para crear un sistema de localización en tiempo real y nace Beacons IoT Technologies.
              Finalmente con la ayuda de su colega Mateo Osorio logra conseguir un empleo en la empresa Cala-Analytics con el 
              cargo de consultor analítico y es allí dónde perfecciona sus habilidades como programador y experticia en el área 
              de tecnologías de la información.
            </p>
            <img
              src="../ami.jpg"
              alt="Foto de Mena"
              width={330}
            />
                   <img
              src="../ofi.png"
              alt="Foto de Mena"
              width={330}
            />
        </div>
      </div>
      <section className="info mt-3">
      <footer className="footer">
        <div className="container d-flex flex-column align-items-center p-3 pb-0">
          <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
          </div>
          <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
            <div className="footer-contact text-center">
              <h2>Contacto</h2>
              <p>johnatanmenasalcedo@gmail.com</p>
              <p>3146148505</p>
            </div>

            <div className="footer-bussines text-center">
              <h2>BIT</h2>
              <p>www.beaconsiottechnologies.com</p>
              <p>beaconsiottechnologies@gmail.com</p>
            </div>
          </div>
        </div>

        <div className="copy d-flex justify-content-between mx-3 fs-6">
          <p>2023, &copy; Johnatan Mena</p>
          {/* <p className="otto text-uppercase">&copy; Johnatan Mena</p> */}
        </div>
      </footer>
      </section>
    </div>
  );
}

export default Bio;