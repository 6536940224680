import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from './inicio';
import Bio from './bio';
import Experiencia from './experiencia';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Inicio/>}/>
          <Route path="/bio" element={<Bio/>}/>
          <Route path="/experiencia" element={<Experiencia/>}/>
          <Route path="/contenido" element=''/>
          <Route path="/info" element=''/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
