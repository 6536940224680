import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './App.css';

function Inicio() {
  return (
    <div>
        <Navbar className='celeste' expand="lg" >
        <Navbar.Brand href="#"><img src="../jm.png" alt="Logo" width="60"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="negrita">
            <Nav.Link href="/">Inicio</Nav.Link>
            <Nav.Link href="/bio">Biografía</Nav.Link>
            <Nav.Link href="/experiencia">Experiencia</Nav.Link>
            <Nav.Link href="/">Contenido</Nav.Link>
            <Nav.Link href="/">Información</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
      <section className="info mt-3 ">
      <div className="caja caja1">
        <h1>Johnatan Mena Salcedo</h1>
        <h3>Ingeniero de Software, Ingeniero biomédico, Senior IoT y Consultor de TI</h3>
          </div>
      </section>
      <section className="">
      <div className="caja caja1">
      <img src='../johnatanmena.png' alt="Logo" width="400"/>
          </div>
      </section>
      </div>
      <section className="info mt-3">
      <footer className="footer">
        <div className="container d-flex flex-column align-items-center p-3 pb-0">
          <h1 className="footer-title text-center">
            Hagámoslo 
            <br />
            <span>Real</span>
          </h1>
          <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
          </div>

          <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
            <div className="footer-contact text-center">
              <h2>Contacto</h2>
              <p>johnatanmenasalcedo@gmail.com</p>
              <p>3146148505</p>
            </div>

            <div className="footer-bussines text-center">
              <h2>BIT</h2>
              <p>www.beaconsiottechnologies.com</p>
              <p>beaconsiottechnologies@gmail.com</p>
            </div>
          </div>
        </div>

        <div className="copy d-flex justify-content-between mx-3 fs-6">
          <p>2023, &copy; Johnatan Mena</p>
          {/* <p className="otto text-uppercase">&copy; Johnatan Mena</p> */}
        </div>
      </footer>
      </section>
    </div>
  );
}

export default Inicio;