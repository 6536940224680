import React from 'react';
import './App.css';
import Barra from './barra';

function Experiencia() {
  return (
    <div>
      <Barra />
      <div>
        <section className=" ">
          <div className="info mt-3">
            <h1>Estas son mis habilidades</h1>
          </div>
        </section>
        <section className=" ">
          <section className="info3 mt-3">
            <div className="info3 mt-3">
              <h4>Gerencia</h4>
              <ul>
                <li>Líder de proyectos</li>
                <li>Gestión administrativa</li>
                <li>Innovación tecnológica</li>
                <li>Líder Técnico</li>
              </ul>
            </div>
          </section>
          <section className="info3 mt-3">
            <div className="info3 mt-3">
              <h4>Cloud (AWS)</h4>
              <ul>
                <li>Redes y computo</li>
                <li>Databases</li>
                <li>IoT Core</li>
                <li>QuickSight</li>
              </ul>
            </div>
          </section>
          <section className="info3 mt-3">
            <div className="info3 mt-3">
              <h4>IBM CLOUD</h4>
              <ul>
                <li>Watson Assistant</li>
                <li>Virtual Server for CLasic</li>
                <li>Databases</li>
                <li>Tecnical Sales</li>
              </ul>
            </div>
          </section>
          <section className="info3 mt-3">
            <div className="info3 mt-3">
              <h4>Ing. Biomédico</h4>
              <ul>
                <li>Equipos biomédicos</li>
                <li>Calibración</li>
                <li>Infraestructura clínica</li>
                <li>Tecnología biomédica</li>
              </ul>
            </div>
          </section>
          <section className="info3 mt-3">
            <div className="info3 mt-3">
              <h4>Desarrollo Back</h4>
              <ul>
                <li>Node js (Javascript)</li>
                <li>Python (Django-Flask)</li>
                <li>PHP</li>
                <li>SQL y noSQL Databases</li>
              </ul>
            </div>
          </section>
          <section className="info3 mt-3">
            <div className="info3 mt-3">
              <h4>Desarrollo Front</h4>
              <ul>
                <li>React js (Javascript)</li>
                <li>jQuery</li>
                <li>Angular (typescript)</li>
                <li>Power BI</li>
              </ul>
            </div>
          </section>
        </section>
        <section className=" ">
          <div className="info mt-3">
            <h1>Esta es mi experiencia</h1>
          </div>
          <section className=" ">
            <div className="info3 mt-3">
              <h4>Beacons IoT Technologies</h4>
              <ul>
                <li>Cargo: Director administrativo</li>
                <li>2023-Current</li>
                <li>Jefe directo: Elizabeth Henao</li>
              </ul>
            </div>
            <div className="info4 mt-3">--</div>
            <div className="info3 mt-3">
              <h4>Bee Consultoría (Banitsmo)</h4>
              <ul>
                <li>Ingeniero de Software</li>
                <li>2024-Current</li>
                <li>Jefe directo: Sergio Alvarez</li>
              </ul>
            </div>
            <div className="info3 mt-3">--</div>
            <div className="info4 mt-3">
              <h4>Cala Analytics</h4>
              <ul>
                <li>Cargo: Consultor Analítico</li>
                <li>2022-Current</li>
                <li>Jefe directo: Cindy Medina</li>
              </ul>
            </div>
            <div className="info4 mt-3">--</div>
            <div className="info3 mt-3">
              <h4>Análisis Metrológico Industrial</h4>
              <ul>
                <li>Cargo: Metrológo</li>
                <li>2020-2022</li>
                <li>Jefe directo: Paola Madrigal</li>
              </ul>
            </div>
          </section>

        </section>
        <section className=" ">
          <div className="info mt-3">
            <h1>Estos son mis logros</h1>
          </div>
        </section>
        <section className=" ">
          <div className="info3 mt-3">
            <h4>Tecnicos</h4>
            <ul>
              <li>Desarrollo de sistemas de localización Real Time</li>
              <li>Integración de sistemas electrónicos en la nube</li>
              <li>Investigación de tecnologías IoT (Beacons BLE)</li>
              <li>Implementación de tablero de control para dispositivos IoT</li>
              <li>Desarrollo de aplicación web para gestión de equipos</li>
            </ul>
          </div>
          <div className="info4 mt-3">
            <h4>Administrativos</h4>
            <ul>
              <li>Liderar equipo de trabajo para proyectos de IoT</li>
              <li>Cumplimiento de objetivos empresariales en términos de ventas</li>
              <li>Acompañamiento y asesoria tecnológica a diferentes empresas </li>
              <li>Planeación y ejecución de trabajos relacionados con tecnologías de la información </li>
              <li>Estrategias Marketing </li>
            </ul>
          </div>
        </section>
      </div>
      <section className="info mt-3">
        <footer className="footer">
          <div className="container d-flex flex-column align-items-center p-3 pb-0">
            <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
            </div>
            <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
              <div className="footer-contact text-center">
                <h2>Contacto</h2>
                <p>johnatanmenasalcedo@gmail.com</p>
                <p>3146148505</p>
              </div>

              <div className="footer-bussines text-center">
                <h2>BIT</h2>
                <p>www.beaconsiottechnologies.com</p>
                <p>beaconsiottechnologies@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="copy d-flex justify-content-between mx-3 fs-6">
            <p>2023, &copy; Johnatan Mena</p>
            {/* <p className="otto text-uppercase">&copy; Johnatan Mena</p> */}
          </div>
        </footer>
      </section>
    </div>
  );
}

export default Experiencia;